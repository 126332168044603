<template>
  <div class="report-view skill-report">
    <div class="viewEdit ttrInfo">
      <!-- start date picker toggle -->
      <b-card class="d-flex">
        <!-- Resource Allocation by Skills Report, Calander -->
        <div class="calendar justify-content-between mt-0 custom-width">
          <p class="task-timesheet">
            Fail Sync History Report
          </p>
          <!-- Previous Arrow, Calander, Next Arrow -->
          <div
            id="date-select"
            class="date-select mr-0"
          >
            <!-- Previous Arrow -->
            <div
              class="calender-prev-next"
              @click="prevMonth()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="21"
              />
            </div>
            <!-- Calander -->
            <div class="date-dropdown">
              <VueDatePicker
                v-model="startDate"
                class="date-picker report-date-picker "
                :type="'month'"
                no-header
              />
            </div>
            <!-- Next Arrow -->
            <div
              class="calender-prev-next calendar-next"
              @click="nextMonth()"
            >
              <feather-icon
                icon="ArrowRightIcon"
                size="21"
              />
            </div>
            <Help
              v-show="$store.state.app.userManualList"
              code="FSHR"
            />
          </div>
        </div>
      </b-card>
      <!-- end date picker toggle -->
      <div class="report-task-section">
        <table-loader
          v-if="loader"
          :fields="fields"
        />
        <div v-else-if="items.length">
          <div class="tableDesigns align-th-table">
            <b-table
              :fields="fields"
              :items="items"
            >
              <template #head(user_id)>
                User Name
              </template>
              <template #head(ip_address)>
                Ip Address
              </template>
              <template #head(message)>
                Message
              </template>
              <template #head(status)>
                Status
              </template>
              <template #cell(user_id)="data">
                <div class="d-flex align-items-center">
                  {{ data.item.user.name }} {{ data.item.user.last_name }}
                </div>
              </template>
              <template #cell(ip_address)="data">
                <div class="d-flex align-items-center">
                  {{ data.item.ip_address }}
                </div>
              </template>
              <template #cell(message)="data">
                <div class="d-flex align-items-center">
                  <p v-b-tooltip.hover.bottom="data.item.message">
                    {{ data.item.message.length > 100 ? data.item.message.substring(0, 100) + '...' : data.item.message }}
                  </p>
                </div>
              </template>
              <template #cell(status)="data">
                <div class="d-flex align-items-center">
                  {{ data.item.status }}
                </div>
              </template>
            </b-table>
          </div>
        </div>
        <div
          v-else
          class="w-100"
        >
          <img
            v-if="totalCount == 0"
            src="@/assets/images/nodatafound/nodata.svg"
            alt="no-data"
            class="no-data-img"
          >
        </div>
        <Pagination
          v-if="totalCount>0"
          :current-page="currentPage"
          :per-page="perPage"
          :total-count="totalCount"
          @currentPage="currentPage = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { VBToggle } from 'bootstrap-vue'
import * as moment from 'moment/moment'

export default {
  name: 'HeaderBlock',
  directives: {
    'b-toggle': VBToggle,
  },
  filters: {
    DateDDMMYYYYFormat(value) {
      return moment(value).format('DD-MM-YYYY')
    },
  },
  data() {
    return {
      perPage: 10,
      totalCount: 0,
      currentPage: 1,
      loader: false,
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().endOf('month').format('YYYY-MM-DD'),
      fields: [
        {
          key: 'user_id',
          label: 'Name',
        },
        {
          key: 'ip_address',
          label: 'Ip Address',
        },
        {
          key: 'message',
          label: 'Message',
        },
        {
          key: 'status',
          label: 'Status',
        },
      ],
      items: [],
    }
  },
  watch: {
    currentPage: {
      handler(value) {
        if (value) {
          this.syncReport()
        }
      },
    },
    perPage() {
      this.syncReport()
    },
    reporting_to() {
      this.syncReport()
    },
    startDate(nv) {
      if (nv) {
        this.currentPage = 1
        setTimeout(() => {
          this.syncReport()
        }, 200)
      }
    },
  },
  mounted() {
    this.syncReport()
  },
  methods: {
    async syncReport() {
      this.loader = true
      const input = {
        month: moment(this.startDate).startOf('month').format('MM'),
        page: this.currentPage,
        per_page: this.perPage,
      }
      const response = await this.getHTTPPostResponse(
        'hr/dashboard/syncList',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.items = data.syncLogs
        this.totalCount = data.count
      }
      this.loader = false
    },
    nextMonth() {
      this.startDate = moment(this.startDate)
        .add(1, 'M')
        .startOf('month')
        .format('YYYY-MM-DD')
      this.endDate = moment(this.endDate)
        .add(1, 'M')
        .endOf('month')
        .format('YYYY-MM-DD')
    },
    prevMonth() {
      this.startDate = moment(this.startDate)
        .subtract(1, 'M')
        .startOf('month')
        .format('YYYY-MM-DD')
      this.endDate = moment(this.endDate)
        .subtract(1, 'M')
        .endOf('month')
        .format('YYYY-MM-DD')
    },
  },
}
</script>

<style lang="scss">
  @import "@/assets/scss/component-css/vieweditheader.scss";
  @import "../../assets/scss/component-css/approvals.css";
  @import "../../assets/scss/component-css/report.css";
  .table-responsive {
    @media (max-width: 3000px) {
      display: inline-table;
    }
    @media (max-width: 1800px) {
      display: block;
    }
  }
  .report-loader-screen {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0, 0.6);
    z-index: 1080;
    img {
      width: 100px;
    }
    .overdlow-sheet {
      overflow-y: hidden;
    }
  }
</style>
